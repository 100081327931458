import React, {useEffect, useMemo, useRef, useState} from 'react';
import {connect} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {useNavigate, useParams} from 'react-router-dom';
import {Transition} from 'react-transition-group';
import PageDocumentDetails from '../../components/PageDocumentDetails';
import SpinSmall from '../../components/SpinSmall';
import NotFoundPage from '../NotFoundPage';
import {
  alertActions,
  bankingActions,
  cardActions,
  companyActions,
  expenseActions,
  invoicesActions,
  subscriptionActions,
  transactionActions
} from '../../state/actions';
import {
  StyledCardPageContainer,
  StyledCardPageContainerSpace,
  StyledCardPageTransactionContainer
} from './StyledCardPage';
import BalancePageHeader from '../../components/pages/TransactionsPage/BalancePageHeader/BalancePageHeader';
import AuthenticationWindow from '../../components/pages/CardsPage/AuthenticationWindow';
import CardDetailsPanel from '../../components/pages/CardPage/CardDetailsPanel';
import DeleteCardModal from '../../components/pages/CardPage/DeleteCardModal';
import TransactionsPanel from '../../components/pages/CardPage/TransactionsPanel';
import TransactionDetailsPanel from '../../components/pages/TransactionsPage/TransactionDetailsPanel';
import TransactionDetails from '../../components/pages/TransactionsPage/TransactionDetails/TransactionDetails';
import useIsEmployeeLoaded from '../../hooks/useIsEmployeeLoaded';
import AddInvoiceModal from '../../components/pages/TransactionsPage/AddInvoiceModal/AddInvoiceModal';
import routes from '../../routes/routes.json';
import {errorHandler} from '../../state/services/request';
import {subscriptionsHelpers} from '../../components/pages/SubscriptionsPage/subscriptionsHelpers';
import {scaHelpers} from '../../scaHelpers';
import {helpers} from '../../helpers';
import {SCAActionsConstants, subscriptionFormFields} from '../../constants';
import {cardsHelpers} from '../../components/pages/CardsPage/cardsHelpers';
import {transactionsHelpers} from '../../components/pages/TransactionsPage/transactionsHelpers';

const initialLoadedTransactionMaxCount = 25;
const numberOfMonthsWithoutAuth = 3;
const duration = 400;

const detailsTransitionStyles = {
  default: {
    opacity: 1,
    transition: `${duration}ms ease-in-out`
  },
  entering: {transform: 'translateX(0)', opacity: 0.75, width: 448},
  entered: {transform: 'translateX(0)', opacity: 1, width: 448},
  exiting: {transform: 'translateX(200%)', opacity: 0.25, width: 0, visibility: 'hidden'},
  exited: {transform: 'translateX(200%)', opacity: 0, width: 0, visibility: 'hidden'}
}


const checkIsEnabledLoadMore = (card, subtractMonthsCount) => {
  const createdDate = helpers.getTimestampDateObject(card?.created_at);
  return subscriptionsHelpers.checkIsEnabledLoadMore(createdDate, subtractMonthsCount)
}

const {
  cardLimitFieldName,
  cardLimitPeriodFieldName,
  limitPeriodShortFieldName
} = subscriptionFormFields;

const cardFields = [cardLimitFieldName, limitPeriodShortFieldName];

const {
  CARD_DELETE_ACTION,
  CARD_EDITION_ACTION,
  CARD_PAUSE_ACTION,
  TRANSACTIONS_OLDER_90_DAYS_LOADING_ACTION,
  TRANSACTIONS_WITHIN_90_DAYS_LOADING_ACTION
} = SCAActionsConstants;

const {getAuthHeaders} = scaHelpers;

const CardPage = ({
  addInvoiceModalProps,
  batchCreateInvoices,
  closeAddInvoiceModal,
  deleteCard,
  getBatchExpenses,
  getBankingCardDetails,
  getMainCardDetails,
  getSubscription,
  getTransactionsList,
  getTags,
  employees,
  pauseCard,
  updateCard,
  updateCardLimits,
  linkExpense,
  unlinkExpense,
  openAddInvoiceModal
}) => {
  const uriParams = useParams();
  const navigate = useNavigate();
  const [t] = useTranslation(['main', 'subscriptions', 'cards']);
  const [cardDetails, setCardDetails] = useState({
    bankingData: null,
    mainData: null
  });
  const [pageLoading, setPageLoading] = useState({loading: true, isExist: true});
  const [transactions, setTransactions] = useState({transactions: [], isLoaded: false});
  const [transactionsWithExpense, setTransactionsWithExpense] = useState([]);
  const [expenses, setExpenses] = useState([]);
  const [authWindowProps, setAuthWindowProps] = useState({open: false});
  const [activeAuthAction, setActiveAuthAction] = useState(null);
  const [loadMoreProps, setLoadMoreProps] = useState({loading: false, isEnabled: false, months: 1});
  const [deleteModalProps, setDeleteModalProps] = useState({loading: false, open: false});
  const [tempData, setTempData] = useState({
    callbackFunction: undefined,
    errorCallbackFunction: undefined,
    successCallbackFunction: undefined,
    data: undefined
  });
  const [isOpenDetails, setIsOpenDetails] = useState({card: true, transaction: false});
  const [selectedTransaction, setSelectedTransaction] = useState(null);
  const [subscription, setSubscription] = useState(null);

  const cardDetailsNodeRef = useRef(null);
  const transactionDetailsNodeRef = useRef(null);

  const {cardId, userId} = useMemo(() => ({
    cardId: uriParams?.cardId,
    userId: uriParams?.userId
  }), [uriParams]);

  const cardDetailsPanel = useMemo(() => ({
    bankingData: cardDetails.bankingData,
    cardData: cardDetails.mainData,
    loading: pageLoading.loading
  }), [pageLoading, cardDetails]);

  const cardNumber = useMemo(() => helpers.hideCardNumber(cardDetails?.mainData?.masked_pan, 6), [cardDetails]);

  const {breadcrumbs, documentTitle} = useMemo(() => {
    let title = '';
    if (!pageLoading.isExist && !pageLoading.loading) {
      title = t('elementNotFound', {name: t('cards:card')});
    } else if (cardNumber) {
      title = `${cardNumber} ${t('detail')}`;
    }
    return {
      breadcrumbs: [
        {title: t('navigation.cards'), href: routes.cardsList},
        {title}
      ],
      documentTitle: `${t('pageTitles.cardDetails')} ${title}`,
    }
  }, [t, cardNumber, pageLoading])

  const finishLoading = (isExist = true) => setPageLoading({...pageLoading, isExist, loading: false});

  const isEmployeeLoaded = useIsEmployeeLoaded();

  useEffect(() => {
    const cardId = uriParams?.cardId;
    const userId = uriParams?.userId;
    getMainCardDetails(
      cardId,
      (mainData) => {
        const subscriptionId = mainData?.subscription_id;
        if (subscriptionId) {
          getSubscription(
            subscriptionId,
            (subscription) => setSubscription(subscription)
          );
        }
        getBankingCardDetails(
          userId,
          cardId,
          (bankingData) => {
            setCardDetails({
              ...cardDetails,
              bankingData,
              mainData
            });
            getTransactions(bankingData);
            finishLoading();
          },
          () => {
            finishLoading(false);
            setTransactions({...transactions, isLoaded: true});
          }
        );
      },
      () => finishLoading(false)
    );
  }, [uriParams]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (isEmployeeLoaded) {
      getTags();
    }
  }, [isEmployeeLoaded]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const transactionsWithExpense = transactionsHelpers.getTransactionsWithExpense({
      expenses,
      employees,
      transactions: transactions.transactions
    });
    setTransactionsWithExpense(transactionsWithExpense);
  }, [transactions, expenses, employees]);

  const getTransactionsListPromises = ({
    card,
    successCallback,
    errorCallback,
    subtractMonthsCount,
    operationName
  }) => {
    const errorFunc = (response) => errorHandler(response, errorCallback);

    const transactionRequest = (subtractMonthsCount, successFunc) => {
      const requestQuery = subscriptionsHelpers.getTransactionRequestQuery({}, subtractMonthsCount);
      getTransactionsList({
        cardId,
        userId,
        headers: getAuthHeaders(operationName || activeAuthAction),
        query: requestQuery,
        successCallback: successFunc,
        errorCallback: (response) => {
          SCAErrorCallback({
            response,
            errorCallback: errorFunc
          });
        }
      });
    }

    let storedTransactions = [];
    activeAuthAction !== operationName && setActiveAuthAction(operationName);

    if (subtractMonthsCount) {
      transactionRequest(
        subtractMonthsCount,
        (response) => successCallback && successCallback(response)
      )
    } else {
      const loadTransactions = (index, maxLength) => {
        const successFunc = (data) => {
          storedTransactions = [...storedTransactions, ...data];
          let nextIndex = index + 1;
          if (loadMoreProps.months !== nextIndex) {
            setLoadMoreProps({
              ...loadMoreProps,
              months: nextIndex,
              isEnabled: checkIsEnabledLoadMore(card, nextIndex)
            });
          }
          if (nextIndex < maxLength && storedTransactions.length <= initialLoadedTransactionMaxCount) {
            setTransactions({...transactions, transactions: storedTransactions});
            loadTransactions(nextIndex, maxLength);
          } else {
            successCallback && successCallback(storedTransactions);
          }
        }

        transactionRequest(index, successFunc);
      }

      loadTransactions(0, numberOfMonthsWithoutAuth);
    }
  }

  const loadBatchExpenses = (expensesIds, previousExpenses = []) => {
    if (!Boolean(expensesIds.length)) return;
    getBatchExpenses(
      {expenses_ids: expensesIds},
      (expenses) => setExpenses([...previousExpenses, ...expenses])
    );
  }

  const SCAErrorCallback = ({response, successCallback, errorCallback}) => {
    scaHelpers.SCAResponseCallback({
      response,
      scaCallback: (scaProps) => {
        setAuthWindowProps({...authWindowProps, ...scaProps});
        successCallback && successCallback();
      },
      errorCallback
    });
  }

  const getTransactions = (card) => {
    getTransactionsListPromises({
      card: card || cardDetails?.bankingData,
      operationName: TRANSACTIONS_WITHIN_90_DAYS_LOADING_ACTION,
      query: {},
      successCallback: (transactions) => {
        const expensesIds = transactions.map(t => t.expense_id).filter(value => value);
        setTransactions({transactions, isLoaded: true});
        loadBatchExpenses(expensesIds);
      },
      errorCallback: () => setTransactions({...transactions, isLoaded: true})
    });
  }

  const handleCloseAuthModal = () => {
    const operationActions = {
      [CARD_DELETE_ACTION]: () => setDeleteModalProps({...deleteModalProps, loading: false}),
      [CARD_EDITION_ACTION]: () => tempData && tempData.errorCallbackFunction && tempData.errorCallbackFunction(),
      [CARD_PAUSE_ACTION]: () => tempData && tempData.callbackFunction && tempData.callbackFunction(),
      [TRANSACTIONS_WITHIN_90_DAYS_LOADING_ACTION]: finishLoading,
      [TRANSACTIONS_OLDER_90_DAYS_LOADING_ACTION]: finishLoading,
    }
    setAuthWindowProps({...authWindowProps, open: false});
    if (operationActions.hasOwnProperty(activeAuthAction)) operationActions[activeAuthAction]();
  }

  const handleOnSuccessAuth = () => {
    const operationActions = {
      [CARD_DELETE_ACTION]: handleDeleteCard,
      [CARD_EDITION_ACTION]: handleEditCard,
      [CARD_PAUSE_ACTION]: handlePauseCard,
      [TRANSACTIONS_WITHIN_90_DAYS_LOADING_ACTION]: getTransactions,
      [TRANSACTIONS_OLDER_90_DAYS_LOADING_ACTION]: handleLoadMore,
    }
    setAuthWindowProps({...authWindowProps, open: false});
    if (operationActions.hasOwnProperty(activeAuthAction)) operationActions[activeAuthAction]();
  }

  const handleLoadMore = () => {
    if (loadMoreProps.isEnabled) {
      const {months} = loadMoreProps;
      const subtractMonthsCount = months + 1;
      const updateLoadMoreProps = {
        ...loadMoreProps,
        loading: true,
        months: subtractMonthsCount,
        isEnabled: checkIsEnabledLoadMore(cardDetails?.bankingData, subtractMonthsCount),
      }
      setLoadMoreProps({...loadMoreProps, loading: true});

      getTransactionsListPromises({
        operationName: TRANSACTIONS_OLDER_90_DAYS_LOADING_ACTION,
        successCallback: (latestTransactions) => {
          const expensesIds = latestTransactions.map(t => t.expense_id).filter(value => value);
          setTransactions({transactions: [...transactions.transactions, ...latestTransactions], isLoaded: true});
          setLoadMoreProps({...updateLoadMoreProps, loading: false});
          loadBatchExpenses(expensesIds, expenses);
        },
        errorCallback: () => setLoadMoreProps({...updateLoadMoreProps, loading: false}),
        subtractMonthsCount: months
      });
    }
  }

  const handleDeleteCard = () => {
    setDeleteModalProps({...deleteModalProps, loading: true});
    deleteCard({
      id: cardId,
      headers: getAuthHeaders(CARD_DELETE_ACTION),
      successCallback: () => {
        handleCloseAuthModal();
        navigate(routes.cardsList);
        alertActions.success(t('cards:successCardDeleteMessage'));
      },
      errorCallback: (response) => {
        SCAErrorCallback({
          response,
          errorCallback: () => setDeleteModalProps({...deleteModalProps, open: true, loading: false})
        });
      }
    })
  }

  const handleOkDeleteModal = () => {
    setActiveAuthAction(CARD_DELETE_ACTION);
    handleDeleteCard();
  }

  const handleCancelDeleteModal = () => setDeleteModalProps({...deleteModalProps, loading: false, open: false});

  const onDelete = () => setDeleteModalProps({...deleteModalProps, loading: false, open: true});

  const onPause = (checked, callbackFunction) => {
    setTempData({...tempData, callbackFunction});
    setActiveAuthAction(CARD_PAUSE_ACTION);
    handlePauseCard(callbackFunction);
  }

  const handlePauseCard = (callbackFunction) => {
    const callBackFunction = callbackFunction || tempData.callbackFunction;
    pauseCard({
      id: cardId,
      headers: getAuthHeaders(CARD_PAUSE_ACTION),
      successCallback: (data) => {
        const status = data?.lock_status;
        setCardDetails({
          ...cardDetails,
          bankingData: {...cardDetails.bankingData, status_code: status},
          mainData: {...cardDetails.mainData, lock_status: status}
        });
        callBackFunction && callBackFunction();
      },
      errorCallback: (response) => {
        SCAErrorCallback({
          response,
          errorCallback: callBackFunction
        });
      }
    })
  }

  const onFormSubmit = ({data, errorCallback, successCallback}) => {
    setTempData({
      ...tempData,
      data,
      errorCallbackFunction: errorCallback,
      successCallbackFunction: successCallback
    });
    setActiveAuthAction(CARD_EDITION_ACTION);
    handleEditCard({data, errorCallback, successCallback});
  }

  const handleEditCard = ({data, errorCallback, successCallback} = {}) => {
    const formData = data || tempData.data;
    const successCallbackFunction = successCallback || tempData.successCallbackFunction;
    const errorCallbackFunction = errorCallback || tempData.errorCallbackFunction;

    // check if need to change card limits
    let isRequiredUpdateCardLimits = false;
    cardFields.forEach(field => {
      if (cardDetails?.mainData[field] !== formData[field]) isRequiredUpdateCardLimits = true;
    });

    // update card details
    const updateMainCardData = (cardBankingData) => {
      updateCard({
        data: formData,
        id: cardId,
        headers: getAuthHeaders(CARD_EDITION_ACTION),
        successCallback: (updatedCardMainData) => {
          let updatedCardDetails = {
            ...cardDetails,
            mainData: updatedCardMainData
          };
          if (cardBankingData) updatedCardDetails = {...updatedCardDetails, bankingData: cardBankingData};
          successCallbackFunction && successCallbackFunction();
          setCardDetails(updatedCardDetails);
        },
        errorCallback: (response) => {
          scaHelpers.SCAResponseCallback({
            response,
            scaCallback: (scaProps) => setAuthWindowProps({...authWindowProps, ...scaProps}),
            errorCallback: errorCallbackFunction
          });
        }
      })
    }

    if (isRequiredUpdateCardLimits) {
      const formLimitsData = cardsHelpers.getSetLimitPayload({
        ...formData,
        [cardLimitPeriodFieldName]: formData[limitPeriodShortFieldName]
      });
      // update card limits
      updateCardLimits({
        cardId,
        userId,
        data: formLimitsData,
        headers: getAuthHeaders(CARD_EDITION_ACTION),
        successCallback: updateMainCardData,
        errorCallback: (response) => {
          SCAErrorCallback({
            response,
            errorCallback: errorCallbackFunction
          });
        }
      });
    } else {
      updateMainCardData();
    }
  }

  const handleCloseTransactionDetails = () => setIsOpenDetails({card: true, transaction: false});

  const handleOnRowClick = (transactionId) => {
    let transaction = transactionsWithExpense.find(d => d.id === transactionId);
    if (transaction) {
      setSelectedTransaction(transaction);
      setIsOpenDetails({card: false, transaction: true});
    }
  }

  const onExpenseUpdate = (updatedExpense, actionType) => {
    if (updatedExpense) {
      let updatedExpenses = helpers.getUpdatedBatchExpensesList(expenses, updatedExpense, actionType);
      setExpenses(updatedExpenses);
      if (selectedTransaction) {
        setSelectedTransaction({
          ...selectedTransaction,
          expense: {
            ...selectedTransaction.expense,
            ...updatedExpense
          }
        })
      }
    }
  }

  const handleOkAttachmentModal = (formData, successCallback, errorCallback) => {
    transactionsHelpers.addInvoiceToTransaction({
      addInvoiceModalProps,
      formData,
      successCallback,
      errorCallback,
      batchCreateInvoices,
      closeAddInvoiceModal,
      linkExpense,
      onExpenseUpdate
    });
  }

  const handleInvoiceRemove = (successCallback, errorCallback) => {
    transactionsHelpers.removeInvoiceFromTransaction({
      t,
      transaction: selectedTransaction,
      successCallback,
      errorCallback,
      unlinkExpense,
      onExpenseUpdate
    });
  }

  const {isExist, loading} = pageLoading;

  return (
    <>
      <PageDocumentDetails title={documentTitle} />
      <BalancePageHeader breadcrumbs={breadcrumbs} />
      <SpinSmall spinning={loading}>
        <StyledCardPageContainer>

          {isExist ? (
            <StyledCardPageContainerSpace
              align='start'
              size='large'
            >
              <TransactionsPanel
                expenses={expenses}
                loading={!transactions.isLoaded}
                loadMoreProps={{
                  ...loadMoreProps,
                  onClick: handleLoadMore
                }}
                transactions={transactionsWithExpense}
                onInvoiceEdit={openAddInvoiceModal}
                onRowClick={handleOnRowClick}
              />
              <StyledCardPageTransactionContainer>
                <Transition
                  nodeRef={cardDetailsNodeRef}
                  in={isOpenDetails.card}
                  timeout={duration}
                >
                  {state => (
                    <CardDetailsPanel
                      style={{
                        ...detailsTransitionStyles.default,
                        ...detailsTransitionStyles[state]
                      }}
                      cardDetails={cardDetailsPanel}
                      onPause={onPause}
                      onDelete={onDelete}
                      onFormSubmit={onFormSubmit}
                    />
                  )}
                </Transition>
                <Transition
                  nodeRef={transactionDetailsNodeRef}
                  in={isOpenDetails.transaction}
                  timeout={duration}
                >
                  {state => (
                    <TransactionDetailsPanel
                      backButtonText={t('back')}
                      className='transaction-details-panel'
                      contentClassName='transaction-details-panel-content'
                      onBack={handleCloseTransactionDetails}
                      style={{
                        ...detailsTransitionStyles.default,
                        ...detailsTransitionStyles[state]
                      }}
                    >
                      <TransactionDetails
                        onExpenseUpdate={onExpenseUpdate}
                        onInvoiceEdit={openAddInvoiceModal}
                        onInvoiceRemove={handleInvoiceRemove}
                        subscription={subscription}
                        transaction={selectedTransaction}
                      />
                    </TransactionDetailsPanel>
                  )}
                </Transition>
              </StyledCardPageTransactionContainer>
            </StyledCardPageContainerSpace>
          ) : !loading && <NotFoundPage />}

        </StyledCardPageContainer>
      </SpinSmall>

      <DeleteCardModal
        {...deleteModalProps}
        cardNumber={cardNumber}
        onOk={handleOkDeleteModal}
        onCancel={handleCancelDeleteModal}
      />

      <AddInvoiceModal
        onCancel={closeAddInvoiceModal}
        open={addInvoiceModalProps.open}
        transaction={addInvoiceModalProps.transaction}
        onOk={handleOkAttachmentModal}
      />

      <AuthenticationWindow
        {...authWindowProps}
        handleCancel={handleCloseAuthModal}
        onSuccess={handleOnSuccessAuth}
        operationName={activeAuthAction}
      />
    </>
  );
}

const mapStateToProps = state => {
  const {addInvoiceModalProps} = state.transaction;
  const {employees} = state.company;

  return {
    addInvoiceModalProps,
    employees
  }
}


const mapDispatchToProps = {
  batchCreateInvoices: invoicesActions.batchCreateInvoices,
  closeAddInvoiceModal: transactionActions.closeAddInvoiceModal,
  deleteCard: cardActions.deleteCard,
  getBatchExpenses: expenseActions.getBatchExpenses,
  getBankingCardDetails: bankingActions.getUserCardDetails,
  getMainCardDetails: cardActions.getCard,
  getSubscription: subscriptionActions.getSubscription,
  getTags: companyActions.getTags,
  getTransactionsList: transactionActions.getUserCardTransactionsList,
  pauseCard: cardActions.pauseCard,
  updateCard: cardActions.updateCard,
  updateCardLimits: bankingActions.updateCardLimits,

  linkExpense: invoicesActions.linkExpense,
  unlinkExpense: invoicesActions.unlinkExpense,
  openAddInvoiceModal: transactionActions.openAddInvoiceModal
}

export default connect(mapStateToProps, mapDispatchToProps)(CardPage);
