import React from 'react';
import PropTypes from 'prop-types';
import {Avatar, Space} from 'antd';
import {StyledVendorDetails, StyledVendorDetailsLink} from './StyledVendorDetails';
import {helpers} from '../../../../helpers';
import {ExternalLinkIcon} from '../../../../icons';
import {subscriptionsHelpers} from '../subscriptionsHelpers';

const VendorDetails = ({
  data,
  ...rest
}) => {
  const reason = helpers.getObjProp(data, 'reason');

  const getServiceProp = (key) => helpers.getObjProp(data.service, key);

  const serviceUrl = getServiceProp('url');

  const vendorDetails = {
    label: subscriptionsHelpers.getSubscriptionName(data),
    src: getServiceProp('logo') || getServiceProp('logo_url'),
    url: `https://${serviceUrl}`,
  }

  return (
    <StyledVendorDetails {...rest}>
      <Avatar src={vendorDetails.src}>
        {helpers.getInitials(vendorDetails.label)}
      </Avatar>
      <div className='vendor-details'>
        <Space align='center' size={6}>
          <div className='vendor-name'>
            {vendorDetails.label}
          </div>
          {serviceUrl && (
            <StyledVendorDetailsLink
              href={vendorDetails.url}
              onClick={(e) => e.stopPropagation()}
              rel='noreferrer'
              target='_blank'
            >
              <ExternalLinkIcon />
            </StyledVendorDetailsLink>
          )}
        </Space>
        <div className='vendor-category'>
          {reason || getServiceProp('category')}
        </div>
      </div>
    </StyledVendorDetails>
  );
}

VendorDetails.propTypes = {
  data: PropTypes.shape({
    reason: PropTypes.string,
    service: PropTypes.shape({
      logo: PropTypes.string,
      name: PropTypes.string,
      url: PropTypes.string
    })
  }),
}

export default VendorDetails;
