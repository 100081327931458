import React, {useEffect, useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import {Button, Divider, Form, Input, Modal, Select} from 'antd';
import {InfoCircleOutlined, MinusOutlined, PlusOutlined} from '@ant-design/icons';
import moment from 'moment';
import {Collapse, UnmountClosed} from 'react-collapse';
import {EuroIcon} from '../../../../icons';
import {helpers} from '../../../../helpers';
import {
  subscriptionFormFields,
  subscriptionFormValues,
  subscriptionPaymentTypesConstants,
  subscriptionStatusesConstants,
} from '../../../../constants';
import {
  StyledAddSingleSubscriptionModal,
  StyledAddSingleSubscriptionModalSegmented,
  StyledAddSingleSubscriptionModalSelectOptionSpace,
  StyledAddSingleSubscriptionModalCardInfo,
  StyledAddSingleSubscriptionModalAccordion
} from './StyledAddSingleSubscriptionModal';
import CompanyTagSelect from '../../TransactionsPage/CompanyTagSelect/CompanyTagSelect';
import {
  BillOptions,
  CardLimitPeriodTypeOptions,
  PaymentOptions,
  StatusOptions,
  SubscriptionsCategoryOptions,
  subscriptionsHelpers
} from '../subscriptionsHelpers';
import {StyledPaymentDetailsInput} from '../../SubscriptionPage/tabComponents/Overview/PaymentDetails/StyledPaymentDetails';
import {
  StyledBudgetDetailsFormSpace
} from '../../SubscriptionPage/tabComponents/Overview/BudgetDetails/StyledBudgetDetails';
import SubscriptionSelect from '../SubscriptionSelect';
import IncreaseLimitAlert from '../IncreaseLimitAlert';
import SpinSmall from '../../../SpinSmall';
import {cardsHelpers} from '../../CardsPage/cardsHelpers';

const {Item} = Form;

const {CARD, FREE} = subscriptionPaymentTypesConstants;
const {ACTIVE, TRIAL} = subscriptionStatusesConstants;
const {
  billedFieldName,
  budgetLimitFieldName,
  cardLimitFieldName,
  cardLimitPeriodFieldName,
  statusFieldName
} = subscriptionFormFields;
const {
  defaultBilled,
  defaultBudgetLimitSubscription,
  defaultCardLimitPeriod
} = subscriptionFormValues;

const defaultFormValues = {
  [billedFieldName]: defaultBilled,
  [budgetLimitFieldName]: defaultBudgetLimitSubscription,
  [cardLimitFieldName]: defaultBudgetLimitSubscription,
  category: undefined,
  [cardLimitPeriodFieldName]: defaultCardLimitPeriod,
  owner: null,
  reason: '',
  [statusFieldName]: ACTIVE,
  service: '',
  tags: []
};

const minSearchSymbolsLength = 3;

const AddSingleSubscriptionModal = ({
  dispatch,
  loading,
  employees,
  employeeEmail,
  isEnabledBanking,
  subscriptionCardLimit,
  handleClose,
  handleOk,
  handleSelectService,
  onSearch,
  open,
  ...rest
}) => {
  const [t] = useTranslation(['main', 'subscriptions']);
  const [form] = Form.useForm();
  const [paymentType, setPaymentType] = useState(isEnabledBanking ? CARD : FREE);
  const [initialFormValues, setInitialFormValues] = useState(defaultFormValues);
  const [billOptions, ] = useState(BillOptions());
  const [cardLimitPeriodTypeOptions] = useState(CardLimitPeriodTypeOptions());
  const [statusOptions,] = useState(StatusOptions());
  const [defaultPaymentOptions,] = useState(PaymentOptions());
  const [ownerOptions, setOwnerOptions] = useState([]);
  const [categoryOptions, ] = useState(SubscriptionsCategoryOptions());
  const [isStartFormEdit, setIsStartFormEdit] = useState(false);
  const [services, setServices] = useState([]);
  const [isLoadingServices, setIsLoadingServices] = useState(false);

  const requiredRules = [{required: true, message: t('validation.fieldIsRequired')}]

  const paymentOptions = useMemo(() => {
    let options = [...defaultPaymentOptions];
    if (!isEnabledBanking) options = options.filter(o => o.value !== CARD);
    return options;
  }, [defaultPaymentOptions, isEnabledBanking]);

  const isCardPaymentType = useMemo(() => paymentType === CARD, [paymentType]);

  const isEnableBudget = useMemo(() => paymentType !== FREE, [paymentType]);

  const service = Form.useWatch('service', {form});

  const cardLimit = Form.useWatch(cardLimitFieldName, {form});
  const cardLimitPeriod = Form.useWatch(cardLimitPeriodFieldName, {form});

  const isTooHighLimit = useMemo(() => {
    return cardsHelpers.isTooHighLimit({
      limit: cardLimit,
      maxLimit: subscriptionCardLimit,
      period: cardLimitPeriod
    });
  }, [cardLimit, cardLimitPeriod, subscriptionCardLimit]);

  useEffect(() => {
    form.setFieldValue('owner', employeeEmail);
  }, [employeeEmail]); // eslint-disable-line react-hooks/exhaustive-deps

  const disabledCreateButton = useMemo(() => service === '' || loading || isTooHighLimit, [service, loading, isTooHighLimit]);

  const vendorProps = useMemo(() => subscriptionsHelpers.getSubscriptionVendorProps({isLoadingServices, t}), [isLoadingServices, t]);

  useEffect(() => {
    if (open) {
      const formFieldValues = {
        ...defaultFormValues,
        owner: employeeEmail
      };
      setInitialFormValues(formFieldValues);
      form.setFieldsValue(formFieldValues);
      setPaymentType(isEnabledBanking ? CARD : FREE);
      setIsStartFormEdit(false);
    } else if (!open && services.length) {
      setServices([]);
    }
  }, [open]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const options = helpers.getEmployeeOptions({employees, employeeEmail, t});
    setOwnerOptions(options);
  }, [employees]); // eslint-disable-line react-hooks/exhaustive-deps

  const trans = (key) => t(`subscriptions:${key}`);

  const formT = (key) => trans(`modal.addSingleSubscription.${key}`);

  const placeholderT = (key) => formT(`placeholder.${key}`);

  const footer = (
    <Button
      disabled={disabledCreateButton}
      onClick={() => !disabledCreateButton && form.submit()}
      size='large'
      type='primary'
    >
      {t('create')}
    </Button>
  );

  const handleChangePaymentType = (value) => {
    let formFields = {};
    const status = form.getFieldValue(statusFieldName);
    setPaymentType(value);

    // trial subscription with payments (non-free) have a card set to a default 1 euro / month
    if (value === CARD && status === TRIAL) {
      formFields = {
        [cardLimitFieldName]: 1
      }
    }
    form.setFieldsValue(formFields);
  }

  const onFieldsChange = (changedFields) => {
    const statusField = changedFields.find(f => f.name.includes(statusFieldName));
    const serviceField = changedFields.find(f => f.name.includes('service'));
    if (statusField) {
      let formFields = {};
      const {value} = statusField;
      if (value === TRIAL && paymentType === CARD) {
        formFields = {
          [cardLimitFieldName]: 1
        }
      }
      form.setFieldsValue(formFields);
    }
    if (serviceField && handleSelectService) handleSelectService(serviceField.value);
    if (!isStartFormEdit) setIsStartFormEdit(true);
  }

  const onChangeTags = (tags) => {
    form.setFieldValue('tags', tags);
    setInitialFormValues({...initialFormValues, tags});
  }

  const handleSubmit = (data) => {
    const getFieldValue = (key, defaultValue) => helpers.getObjProp(data, key, defaultValue);
    const category = getFieldValue('category');
    const cardLimit = data[cardLimitFieldName];
    const {owner} = data;
    let formData = {
      card_owner: owner,
      owner: owner,
      status: data[statusFieldName],
      payment_type: paymentType,
      reason: data.reason,
      service: data.service,
      tags: data.tags
    }

    if (category) {
      formData = {
        ...formData,
        category: getFieldValue('category')
      }
    }

    if (isEnableBudget) {
      formData = {
        ...formData,
        [billedFieldName]: getFieldValue(billedFieldName),
        [budgetLimitFieldName]: getFieldValue(budgetLimitFieldName, defaultBudgetLimitSubscription)
      }
    }

    if (isCardPaymentType) {
      formData = {
        ...formData,
        [cardLimitFieldName]: cardLimit || defaultBudgetLimitSubscription,
        [cardLimitPeriodFieldName]: getFieldValue(cardLimitPeriodFieldName, defaultCardLimitPeriod),
        expected_first_payment: helpers.getMomentUnixTimestamp(moment())
      }
    }
    handleOk && handleOk(formData);
  }

  const clearServices = () => {
    setServices([]);
    setIsLoadingServices(false);
  }

  const handleOnSearch = (value) => {
    value = subscriptionsHelpers.getSearchedDomainName(value);
    if (value.length < minSearchSymbolsLength && Boolean(services.length)) {
      clearServices();
    } else if (value !== '' && onSearch) {
      setIsLoadingServices(true)
      onSearch(
        value,
        (services) => {
          setServices(services);
          setIsLoadingServices(false);
        },
        clearServices
      );
    }
  }

  const onClose = (e, handleAction = handleClose) => {
    const handleOnClose = () => {
      handleAction && handleAction();
      clearServices();
    }
    if (isStartFormEdit) {
      Modal.confirm({
        cancelText: t('no'),
        cancelButtonProps: {className: 'green-btn-outlined', size: 'large'},
        okButtonProps: {size: 'large', type: 'primary'},
        okText: t('yes'),
        onOk: handleOnClose,
        title: formT('confirm.title')
      });
    } else {
      handleOnClose();
    }
  }

  return (
    <StyledAddSingleSubscriptionModal
      forceRender
      footer={footer}
      onClose={onClose}
      open={open}
      title={`${t('add')} ${t('subscription')}`}
      width={472}
      {...rest}
    >
      <SpinSmall spinning={loading}>
        <Form
          initialValues={initialFormValues}
          onFieldsChange={onFieldsChange}
          onFinish={handleSubmit}
          layout='vertical'
          form={form}
          requiredMark={false}
        >
          <Item
            className={service !== '' && 'd-none-label'}
            label={t('vendor')}
            name='service'
            tooltip={vendorProps.tooltip}
          >
            <SubscriptionSelect
              autocompleteProps={vendorProps.autocomplete}
              inputProps={vendorProps.input}
              minSearchSymbolsLength={minSearchSymbolsLength}
              onSearch={handleOnSearch}
              services={services}
            />
          </Item>
          <Item
            label={t('Owner')}
            name='owner'
          >
            <Select
              options={ownerOptions}
              size='large'
            />
          </Item>
          <StyledAddSingleSubscriptionModalSegmented
            block={true}
            onChange={handleChangePaymentType}
            options={paymentOptions}
            size='large'
            value={paymentType}
          />

          <Collapse isOpened={isCardPaymentType}>
            <StyledAddSingleSubscriptionModalCardInfo align='start'>
              <InfoCircleOutlined />
              {placeholderT('card')}
            </StyledAddSingleSubscriptionModalCardInfo>
          </Collapse>

          <Item
            label={t('status')}
            name={statusFieldName}
          >
            <Select
              options={statusOptions.map(option => ({
                ...option,
                label: (
                  <StyledAddSingleSubscriptionModalSelectOptionSpace>
                    {option.image}
                    {option.label}
                  </StyledAddSingleSubscriptionModalSelectOptionSpace>
                )
              }))}
              size='large'
            />
          </Item>
          <Item
            label={t('costCentre')}
            name='tags'
            tooltip={{ icon: <InfoCircleOutlined /> }}
          >
            <CompanyTagSelect
              inputProps={{
                placeholder: placeholderT('costCentre'),
                size: 'large'
              }}
              onChange={onChangeTags}
              selected={initialFormValues.tags}
            />
          </Item>
          <UnmountClosed isOpened={isEnableBudget}>
            <StyledBudgetDetailsFormSpace size='middle'>
              <Item
                label={t('Budget')}
                name={budgetLimitFieldName}
                required
              >
                <StyledPaymentDetailsInput
                  addonBefore={<EuroIcon />}
                  min={1}
                  size='large'
                  type='number'
                />
              </Item>
              <Item
                label=' '
                name={billedFieldName}
                rules={requiredRules}
              >
                <Select
                  options={billOptions}
                  size='large'
                />
              </Item>
            </StyledBudgetDetailsFormSpace>
          </UnmountClosed>
          <Divider />
          <StyledAddSingleSubscriptionModalAccordion
            ghost
            expandIconPosition='end'
            expandIcon={({ isActive }) => isActive ? <MinusOutlined /> : <PlusOutlined /> }
            items={[
              {
                key: '1',
                label: `${t('advanced')} ${t('settings')}`,
                children: (
                  <>
                    <Collapse isOpened={isCardPaymentType}>
                      <StyledBudgetDetailsFormSpace size='middle'>
                        <Item
                          label={`${t('card')} ${t('limit')}`}
                          name={cardLimitFieldName}
                        >
                          <StyledPaymentDetailsInput
                            addonBefore={<EuroIcon />}
                            min={1}
                            size='large'
                            type='number'
                          />
                        </Item>
                        <Item
                          label=' '
                          name={cardLimitPeriodFieldName}
                          rules={requiredRules}
                        >
                          <Select
                            options={cardLimitPeriodTypeOptions}
                            size='large'
                          />
                        </Item>
                      </StyledBudgetDetailsFormSpace>
                    </Collapse>
                    <Collapse isOpened={isTooHighLimit}>
                      <IncreaseLimitAlert
                        limit={subscriptionCardLimit}
                        handleBeforeRedirect={onClose}
                      />
                    </Collapse>
                    <Item
                      label={t('category')}
                      name='category'
                    >
                      <Select
                        allowClear={true}
                        options={categoryOptions}
                        placeholder={placeholderT('category')}
                        size='large'
                      />
                    </Item>
                    <Item
                      label={trans('reason')}
                      name='reason'
                    >
                      <Input
                        placeholder={placeholderT('reason')}
                        size='large'
                      />
                    </Item>
                  </>
                )
              }
            ]}
          />
        </Form>
      </SpinSmall>
    </StyledAddSingleSubscriptionModal>
  );
}

AddSingleSubscriptionModal.propTypes = {
  loading: PropTypes.bool,
  handleClose: PropTypes.func,
  handleOk: PropTypes.func,
  handleSelectService: PropTypes.func,
  onSearch: PropTypes.func
}

AddSingleSubscriptionModal.defaultProps = {
  loading: false
}

const mapStateToProps = state => {
  const {isEnabledBanking} = state.banking;
  const {employees} = state.company;
  const {employee} = state.user;
  const {subscriptionCardLimit} = cardsHelpers.getPaymentCardLimit(state);
  return {
    employees,
    employeeEmail: employee.email,
    isEnabledBanking,
    subscriptionCardLimit
  }
}

export default connect(mapStateToProps, null)(AddSingleSubscriptionModal);

